<template>
  <div class="ma-1 pa-1">
    <v-toolbar light>
      <v-toolbar-title>
        Manage Users
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" to="/dashboard/users/create/">Create User</v-btn>
    </v-toolbar>
    <v-data-table :headers="headers" :items="users">
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <!-- <td>{{ item.name }}</td> -->
            <td>{{ item.email }}</td>
            <td>{{ item.country }}</td>
            <td class="justify-center">
              <v-icon v-if="item.is_active">mdi-check</v-icon>
              <v-icon v-else>mdi-close</v-icon>
            </td>
            <td class="justify-center">
              <v-icon v-if="item.is_superuser">mdi-check</v-icon>
              <v-icon v-else>mdi-close</v-icon>
            </td>
            <td class="justify-center">
              <v-icon v-if="item.is_verified">mdi-check</v-icon>
              <v-icon v-else>mdi-close</v-icon>
            </td>
            <td class="justify-center layout px-0 py-1">
              <v-tooltip top>
                <span>Edit User</span>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" text :to="{name: 'Edit User', params: {id: item.id}}">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
              <v-tooltip top v-if="!checkUser(item)">
                <span>Delete User</span>
                <template v-slot:activator="{ on }">
                  <!-- <v-btn color="error" v-on="on" text :to="{name: 'Edit User', params: {id: item.id}}"> -->
                  <v-btn color="error" v-on="on" text @click.prevent="getConfirm(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <v-dialog
      v-model="deletedialog"
      persistent
      width="350"
      :ripple="foo"
    >
      <v-card>
        <v-toolbar color="warning" class="justify-center mb-2 rounded-0">
          <v-toolbar-title>Confirm User Delete</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <p>You are about to delete a user. If you are sure, 
            type the user's email:</p>
          <p class="justify-center text-center" style="background-color: #e6e6e6">
            <pre>{{ todelete }}</pre>
          </p> 
          <p>in the text input below and press confirm.</p>
          <v-form
            v-model="valid"
            ref="confirmform"
            lazy-validation
          >
            <v-text-field
              v-model="deletecode"
              :rules="confirmRule"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="error"
            @click.prevent="delcancel">
            Cancel
          </v-btn>
          <v-btn color="warning"
            @click.prevent="delconfirm">
            Confirm
          </v-btn>
        </v-card-actions>
        
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {sync, get, call} from 'vuex-pathify'

export default {
  data() {
    return {
      // confirm delete variables
      valid: false,
      deletecode: "",
      todelete: "",
      deluserid: "",
      deletedialog: false,
      foo: false,
      headers: [
        {
          text: 'Email',
          sortable: true,
          value: 'email',
          align: 'left'
        },
        {
          text: 'Country',
          sortable: true,
          value: 'country',
          align: 'left'
        },
        {
          text: 'Is Active',
          sortable: true,
          value: 'is_active',
          align: 'left'
        },
        {
          text: 'Is Superuser',
          sortable: true,
          value: 'is_superuser',
          align: 'left'
        },
        {
          text: 'Is Verified',
          sortable: true,
          value: 'is_verified',
          align: 'left'
        },
        {
          text: 'Actions',
          value: 'id',
          align: 'center'
        }
      ] 
    }
  },
  mounted () {
    this.$store.dispatch('adminusers/fetchUsers')
  },
  computed: {
    ...sync('adminusers', [
      'users',
      'loading',
      'error'
    ]),
    current_user: get('newauth@userProfile.email'),
    confirmRule() {
      return [
       () => (this.todelete === this.deletecode) || 'Value is not correct email',
       v => !!v || 'Fill value to confirm'
      ]
    },
  },
  methods: {
    checkUser (item) {
      if (item.email === this.current_user) {
        return true
      } else {
        return false
      }
    },
    getConfirm (item) {
      // console.log(item)
      this.todelete = item.email
      this.deluserid = item.id
      this.deletedialog = true
    },
    delcancel () {
      this.$refs.confirmform.resetValidation()
      this.deletecode = ""
      this.todelete = ""
      this.deluserid = ""
      this.deletedialog = false
    },
    delconfirm () {
      if (this.$refs.confirmform.validate()) {
        this.deletecode = ""
        // delete the user
        this.$store.dispatch('adminusers/deleteUser', {'userid': this.deluserid})
      }
      else {
        this.foo = true
        console.log('the validation is not correct')
      }
      this.deletecode = ""
      this.deluserid = ""
      
      this.deletedialog = false
    }
  }
}
</script>
